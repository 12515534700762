/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 * @author Gowtham Prasath
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import debounce from "lodash.debounce";
import Button from "@mui/material/Button"; // Assuming you're using Material-UI
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";
import location from "../../../src/Assets/newMobileIcon/location.png";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import CustomConsole from "../../coustomConsole";
// Assuming these are defined somewhere in your project

const MapContainer = ({
  handleClose,
  setSlectedAddress,
  setOpenAddress,
  setOpenAddAddress,
  editAddressData,
  addressType,
}) => {
  const [selectedAddress, setSelectedAddress] = useState("");
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState(2);
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loadingBtn, setLoadingBtn] = React.useState(false);
  const mapRef = useRef(null);
  const bounds = {
    north: 37.0,
    south: 36.0,
    west: -123.0,
    east: -121.0,
  };
  //function to  call address api based on lat and lang
  const fetchAddressFromCoordinates = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA2PFGPdRerotSwHXaucLvj4_9AdjAtjig`
      );
      const data = await response.json();
      setLoadingBtn(true);
      if (
        data &&
        data.results &&
        data.results[0] &&
        data.results[0].formatted_address
      ) {
        setSlectedAddress(data.results[0]);
        setLoadingBtn(false);
        return data.results[0].formatted_address;
      } else {
        setLoadingBtn(false);
        throw new Error("Invalid geocoding response");
      }
    } catch (error) {
      CustomConsole("Error fetching address:", error);
      setLoadingBtn(false);
      return "";
    }
  };

  //function to get lat and lag base on click
  const handleMapClick = async (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    // Set the selected location
    setUserLocation({ lat, lng });

    // Fetch address from coordinates
    const address = await fetchAddressFromCoordinates(lat, lng);
    setSelectedAddress(address);
  };

  //function to handel map drag start
  const handleMapDragStart = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      setUserLocation({ lat: center.lat(), lng: center.lng() });
    }
  };

  //function to handel map drag
  const handleMapDrag = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      setUserLocation({ lat: center.lat(), lng: center.lng() });
    }
  };

  //function to handel map drag end
  const handleMapDragEnd = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      debouncedFetchAddress(center.lat(), center.lng());
    }
  };

  const handleUserLocationClick = () => {
    // Set map center to the user's location
    setOpenAddAddress(true);
    // handleClose();
  };

  //debounce function to get address after time out 1s
  const debouncedFetchAddress = useCallback(
    debounce(async (lat, lng) => {
      const address = await fetchAddressFromCoordinates(lat, lng);
      setSelectedAddress(address);
    }, 1000),
    []
  );

  //function to display map based on lat and lang on componenet mount
  useEffect(() => {
    CustomConsole(addressType);
    // debugger
    if (addressType === "add") {
      GetUserLocationOnClick();
    } else if (addressType === "edit" && editAddressData) {
      const latitude = parseFloat(editAddressData?.latitude);
      const longitude = parseFloat(editAddressData?.longitude);
      if (!isNaN(latitude) && !isNaN(longitude) && latitude && longitude) {
        setMapCenter({ lat: latitude, lng: longitude });
        setUserLocation({ lat: latitude, lng: longitude });
        setZoom(17);
        fetchAddressFromCoordinates(latitude, longitude).then((address) =>
          setSelectedAddress(address)
        );
      } else {
        GetUserLocationOnClick();
      }
    }
  }, [addressType]);

  //function to user location
  const GetUserLocationOnClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({ lat: latitude, lng: longitude });
          setUserLocation({ lat: latitude, lng: longitude });
          setZoom(17);
          // Fetch address from coordinates
          fetchAddressFromCoordinates(latitude, longitude).then((address) =>
            setSelectedAddress(address)
          );
        },
        (error) => {
          CustomConsole("Error getting user location:", error);
          handleGeolocationError(error);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 } // Optional options
      );
    } else {
      CustomConsole("Geolocation is not supported by this browser.");
    }
  };

  //function to handel map zoom option
  const handleZoomChanged = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
  };

  //function to handel google location error
  const handleGeolocationError = (error) => {
    CustomConsole(error);
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        CustomConsole(result.state);
        // If granted, you can directly call your function here
      } else if (result.state === "prompt") {
        CustomConsole(result.state);
        showEnableLocationMessage("Please enable location services.");
      } else if (result.state === "denied") {
        CustomConsole("Location access denied by user");
        showEnableLocationMessage(
          "Location access is denied. Please enable location services in your browser settings."
        );
      }
      result.onchange = function () {
        CustomConsole(result.state);
        if (result.state === "granted") {
          GetUserLocationOnClick();
        } else if (result.state === "denied") {
          showEnableLocationMessage(
            "Location access is denied. Please enable location services in your browser settings."
          );
        }
      };
    });
  };

  //alertmsg to turnon location
  const showEnableLocationMessage = (message) => {
    // Display a custom message or UI to the user
    alert(message); // Example using an alert, but you can use a custom UI element
  };
  return (
    <>
      <LoadScript googleMapsApiKey="AIzaSyA2PFGPdRerotSwHXaucLvj4_9AdjAtjig">
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "64vh" }}
          center={mapCenter}
          zoom={zoom}
          // onClick={handleMapClick}
          onDragStart={handleMapDragStart}
          onDrag={handleMapDrag}
          onDragEnd={handleMapDragEnd}
          onZoomChanged={handleZoomChanged}
          onLoad={(map) => {
            mapRef.current = map;
          }}
          options={{
            gestureHandling: "greedy", // Allows single-finger dragging
            minZoom: 15, // Set the minimum zoom level
            maxZoom: 18, // Set the maximum zoom level
            streetViewControl: false, // Disable street view control
            fullscreenControl: false, // Disable fullscreen control if needed
            streetViewControl: false,
            mapTypeId: "roadmap",
            mapTypeControl: false,
            // restriction: {
            //   latLngBounds: bounds,
            //   strictBounds: true,
            // },
          }}
        >
          {userLocation && <Marker position={userLocation} />}
          <div
            style={{
              position: "absolute",
              top: 130,
              right: 10,
              zIndex: 1,
            }}
          >
            <button
              onClick={GetUserLocationOnClick}
              style={{
                padding: "5px 10px",
                background: "#fff",
                border: "1px solid #ccc",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <img
                src={location}
                alt="User Location"
                style={{ width: "20px", height: "20px" }}
              />
            </button>
          </div>
        </GoogleMap>
      </LoadScript>

      <div
        style={{
          borderTopRightRadius: "1rem",
          borderTopLeftRadius: "1rem",
          background: appColors.homePageCard,
        }}
      >
        <p
          style={{
            textAlign: "start",
            fontWeight: "600",
            color: appColors.darkGray,
            marginLeft: "1rem",
            marginTop: ".5rem",
            marginBottom: ".5rem",
          }}
        >
          Address Details:
        </p>
        <p
          style={{
            textAlign: "start",
            marginLeft: "1rem",
            fontWeight: "500",
            color: appColors.darkGray,
            fontSize: appTheme.fontSizeMedium,
          }}
        >
          {selectedAddress}
        </p>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-evenly",
            gap: 2,
            padding: "0 1rem 1rem 1rem",
          }}
        >
          <LoadingButton
            onClick={handleUserLocationClick}
            loading={loadingBtn}
            variant="contained"
            size="small"
            sx={{
              // padding: "5px 10px",
              background: appColors.textColorNew,
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Confirm & Continue
          </LoadingButton>
          <Button
            onClick={handleClose}
            variant="contained"
            size="small"
            sx={{
              // padding: "5px 10px",
              background: appColors.lightblue,
              borderRadius: "5px",
              cursor: "pointer",
              color: appColors.black,
              marginBottom: { xs: "1rem", sm: "0" },
              "&:hover": {
                background: appColors.lightblue,
                color: appColors.black,
              },
            }}
          >
            Close
          </Button>
        </Box>
      </div>
    </>
  );
};

export default MapContainer;
