/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import { toast } from "react-toastify";
import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";

//Update Customer Profile Picture, GST File , Billing Name & GST Number
export const UpdateUserProfile = (
  billingName,
  gstNumber,
  panNumber,
  gstFileUrl,
  profileUrl,
  notificationFlag
) => {
  const billing_name = billingName;
  const gst = gstNumber;
  const gst_file_url = gstFileUrl;
  const profile_pic_url = profileUrl;
  const enable_notification = notificationFlag;
  const pan_number = panNumber;
  const status = true;
  let error = "";

  console.log("Billing Name: " + billing_name);
  let api = useAxios();
  return async (dispatch) => {
    let response = await api
      .put(`/SCM/consumer_profile`, {
        billing_name,
        gst,
        gst_file_url,
        profile_pic_url,
        status,
        enable_notification,
        pan_number,
      })
      .catch((e) => {
        error = e;
        // console.log(`Error: ${e}`);
      });
    if (error !== "" && error.message.includes("400")) {
    } else if (response.status === AppMetaData.httpStatus.SUCCESS) {
      toast.dark(response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
      dispatch({
        type: actionType.UPDATE_USER_PROFILE,
        payload: {
          data: response.data,
          updateFlag: true,
        },
      });
    } else {
      // console.log("ELSE: Response in GetPdwAllOrders Action Error");
    }
  };
};

//Update or Change the User Password
//Note: After Successfull Login only can use this Action
export const UpdatePassword = (oldPassword, newPassword) => {
  let new_password = "" + newPassword;
  let old_password = "" + oldPassword;
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/SCM/change_password/user`, {
        new_password,
        old_password,
      });
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        dispatch({
          type: actionType.UPDATE_PASSWORD,
          payload: response.data,
        });
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//Update or Change the User Password
//Note: After Successfull Login only can use this Action
export const UpdatePhoneNumber = (newPhoneNumber, oldPhoneNumber) => {
  let new_phone = "" + newPhoneNumber;
  let old_phone = "" + oldPhoneNumber;
  // let error = "";
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/SCM/changephone/user`, {
        new_phone,
        old_phone,
      });
      sessionStorage.setItem("checksum", response.data.checksum);
      toast.dark("Verification Code Sent Successfully...", {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
      dispatch({
        type: actionType.UPDATE_PHONE_NUMBER,
        payload: response.data,
      });
    } catch (e) {
      // console.log("Error.response.data: " + JSON.stringify(e.response));
      if (e.response.data.msg !== "" && e.response.data.msg !== undefined) {
        toast.dark(e.response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    }
  };
};
//Verify the New Phone Number
export const VerifyPhoneNumber = (verificationCode) => {
  const checksum = sessionStorage.getItem("checksum");
  const verify_code = "" + verificationCode;
  let error = "";

  let api = useAxios();
  return async (dispatch) => {
    let response = await api
      .put(`/SCM/changephone/verify_user`, {
        checksum,
        verify_code,
      })
      .catch((e) => {
        error = e;
        // console.log(`Error: ${e}`);
      });
    if (error !== "" && error.message.includes("400")) {
      toast.dark("Invalid OTP, Please Enter Valid OTP", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    } else if (response.status === AppMetaData.httpStatus.SUCCESS) {
      toast.dark(response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
      dispatch({
        type: actionType.UPDATE_PHONE_NUMBER_VERIFY,
        payload: true,
      });
      sessionStorage.removeItem("checksum");
    } else {
      // console.log("ELSE: Response in GetPdwAllOrders Action Error");
    }
  };
};

//Update or Change the User Password
//Note: After Successfull Login only can use this Action
export const UpdateEmail = (newEmail, oldEmail) => {
  let new_email = "" + newEmail;
  let old_email = "" + oldEmail;
  let error = "";
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/SCM/change_email/user`, {
        new_email,
        old_email,
      });
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // console.log(
        //   `response.status: ${response.status}, ${JSON.stringify(response)}`
        // );
        sessionStorage.setItem("checksum", response.data.checksum);
        toast.dark("Verification Code Sent Successfully...", {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        dispatch({
          type: actionType.UPDATE_EMAIL,
          payload: response.data,
        });
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
//Verify the New Phone Number
export const VerifyEmail = (verificationCode) => {
  const checksum = sessionStorage.getItem("checksum");
  const verify_code = "" + verificationCode;
  let error = "";

  let api = useAxios();
  return async (dispatch) => {
    let response = await api
      .put(`/SCM/change_email/verify_user`, {
        checksum,
        verify_code,
      })
      .catch((e) => {
        error = e;
        // console.log(`Error: ${e}`);
      });
    if (error !== "" && error.message.includes("400")) {
      toast.dark("Invalid OTP, Please Enter Valid OTP", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    } else if (response.status === AppMetaData.httpStatus.SUCCESS) {
      toast.dark(response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
      dispatch({
        type: actionType.UPDATE_EMAIL_VERIFY,
        payload: true,
      });
      sessionStorage.removeItem("checksum");
    } else {
      // console.log("ELSE: Response in GetPdwAllOrders Action Error");
    }
  };
};

//Get Use Address List
export const GetUserAdressBook = () => {
  let api = useAxios();
  let error = null;
  return async (dispatch) => {
    dispatch({ type: actionType.FETCH_DATA_START_ADDRESS });
    setTimeout(() => {
      dispatch({ type: actionType.FETCH_DATA_STOP_ADDRESS });
    }, 3000);
    let response = await api.get(`/SCM/addresses?is_only_address=true`).catch((e) => {
      error = "" + e;
    });
    if (error !== null) {
      //Error in response (Internet Issue or Inavlid Detaills)
    } else if (response.status === AppMetaData.httpStatus.SUCCESS) {
      dispatch({
        type: actionType.GET_USER_ADDRESS_LIST,
        payload: { data: response.data, statusFlag: true },
      });
    } else {
      dispatch({
        type: actionType.GET_USER_ADDRESS_LIST,
        payload: { data: response.data, statusFlag: true },
      });
    }
  };
};

//Add New Address
export const AddNewAdress = (addressDetails, isPrimary) => {
  const delTime =
    addressDetails.deliveryTime === "1"
      ? AppMetaData.deliveryTimingOptions.SIX_SIX
      : AppMetaData.deliveryTimingOptions.ANY_TIME;
  const data = {
    address_line1: addressDetails.address1,
    address_line2: addressDetails.address2,
    address_line3: addressDetails.address3,
    contact_name: addressDetails.name,
    contact_phone: addressDetails.phone,
    delivery_timing_option: delTime,
    is_primary: isPrimary,
    landmark: addressDetails.landmark,
    latitude: addressDetails.latitude,
    latlongaddress: "",
    longitude: addressDetails.longitude,
    pincode: addressDetails.pincode,
    service_area: addressDetails.serviceArea,
    status: true,
    address_type: addressDetails.address_type,
  };
  let api = useAxios();
  let error = null;
  return async (dispatch) => {
    let response = await api.post(`/SCM/addresses`, data).catch((e) => {
      error = "" + e;
    });
    if (error !== null) {
      //Error in response (Internet Issue or Inavlid Detaills)
    } else if (response.status === AppMetaData.httpStatus.SUCCESS) {
      toast.dark(response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
      dispatch({
        type: actionType.ADD_NEW_ADDRESS,
        payload: { data: response.data, statusFlag: true },
      });
      dispatch(GetUserAdressBook());
    } else {
      // console.log("ELSE: Response in GetPdwAllOrders Action Error");
    }
  };
};

//Edit Existing Address
export const EditAdress = (addressDetails) => {
  // console.log(
  //   "AddNewAdress Action addressDetails: " + JSON.stringify(addressDetails)
  // );
  console.log(addressDetails);
  const delTime =
    addressDetails.deliveryTime === "1"
      ? AppMetaData.deliveryTimingOptions.SIX_SIX
      : AppMetaData.deliveryTimingOptions.ANY_TIME;

  const data = {
    address_line1: addressDetails.address1,
    address_line2: addressDetails.address2,
    address_line3: addressDetails.address3,
    contact_name: addressDetails.name,
    contact_phone: addressDetails.phone,
    delivery_timing_option: delTime,
    is_primary: addressDetails.isPrimary,
    landmark: addressDetails.landmark,
    latitude: addressDetails.latitude,
    latlongaddress: addressDetails.latlongaddress,
    longitude: addressDetails.longitude,
    pincode: addressDetails.pincode,
    service_area: addressDetails.serviceArea,
    address_type: addressDetails.address_type,
    status: true,
  };
  let api = useAxios();
  let error = null;
  return async (dispatch) => {
    let response = await api
      .put(`/SCM/addresses/my/${addressDetails.addressId}`, data)
      .catch((e) => {
        error = "" + e;
      });
    if (error !== null) {
      //Error in response (Internet Issue or Inavlid Detaills)
    } else if (response.status === AppMetaData.httpStatus.SUCCESS) {
      toast.dark(response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
      dispatch({
        type: actionType.EDIT_EXISTING_ADDRESS,
        payload: { data: response.data, statusFlag: false },
      });
      dispatch(GetUserAdressBook());
    } else {
      // console.log("ELSE: Response in GetPdwAllOrders Action Error");
    }
  };
};

//Delete User Address
export const DeleteUserAddress = (id) => {
  let api = useAxios();
  let error = null;
  return async (dispatch) => {
    let response = await api.put(`/SCM/address/${id}/remove`).catch((e) => {
      error = "" + e;
      //   console.log("Error Response: " + JSON.stringie(e.response));
    });
    if (error !== null) {
      //Error in response (Internet Issue or Inavlid Detaills)
    } else if (response.status === AppMetaData.httpStatus.SUCCESS) {
      // console.log("response: " + JSON.stringify(response));
      toast.dark(response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
      dispatch({
        type: actionType.DELETE_USER_ADDRESS,
        payload: { data: response.data, statusFlag: true },
      });
      dispatch(GetUserAdressBook());
    } else {
      // console.log("ELSE: Response in GetPdwAllOrders Action Error");
    }
  };
};

//function to change address status
export const ResetAddressStatus = (data) => ({
  type: actionType.RESET_ADDRESS_STATE,
});
