/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import appColors from "../../../Assets/AppTheme/appColors";
import appTheme from "../../../Assets/AppTheme/appTheme";
import GiftBOX from "../../../Assets/newMobileIcon/menu/giftBox.svg";
import halfStar from "../../../Assets/newMobileIcon/GiftCards/halfStar.svg";
import Star from "../../../Assets/newMobileIcon/GiftCards/Star.svg";
import StyledInputField from "../../../Components/TextFields/styledTextFile";
import ButtonCustom from "../../../Components/SkuButtons/ButtonCustom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { CheckUserAvailableDetails } from "../../../Redux/actions/giftCardAction";
import NotificationInfoDialog from "../../../Components/DialogBoxes/info/notificationDialog";
import appMessages from "../../../Assets/AppMeta/appMessages";
import GiftCardPayOptions from "../../../Components/Buttons/priceButtons";
import CustomDivider from "../../../Components/CommonComponents/customDivider";
export default function PriceDetailsandNotification({
  notifyUserClick,
  setNotifyUserClick,
  userPhone,
  setUserPhone,
  userEmailId,
  setUserEmailId,
  priceError,
  setPriceError,
  emailError,
  setEmailError,
  phoneError,
  setPhoneError,
  price,
  setPrice,
  setPasswordRequired,
  passwordRequired,
  loadWalletFlag,
  setLoadWalletFlag,
  setReciverInfo,
  reciverInfo,
  errorMsg,
  setErrorMsg,
  handelAmountField,
  handelClick,
  isUser,
  setIsUser,
  setButtonError,
  setPaymentButtonFlag,
}) {
  //useSelector with all gift card details
  const giftCardDetails = useSelector(
    (store) => store.GiftCardDetails.giftCardSetting
  );
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const [userVerifyFlag, setUserVerifyFlag] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  //useEffect to set default amount
  useEffect(() => {
    if (giftCardDetails) {
      setPrice(giftCardDetails?.maximum_amount);
    }
  }, [giftCardDetails]);
  //function to handel buy gift card back button
  const handelNotifyUser = (e) => {
    if (!e.target.checked && userPhone !== "") {
      setOpenInfoDialog(true);
    } else {
      setNotifyUserClick(e.target.checked);
    }
  };
  console.log("notifyUserClick");

  console.log(notifyUserClick);

  //function to handel user phone field
  const handelPhoneField = (e) => {
    let value = e.target.value;

    const phoneRegEx = /^[5-9][0-9]{9}$/;
    if (!phoneRegEx.test(value) && value !== "") {
      setPhoneError(`Please Enter Proper Phone Number`);
    } else {
      setPhoneError("");
    }
    setIsUser(false);
    setUserPhone(e.target.value);
    setUserData([]);
    setButtonError(true);
    setErrorMsg("");
    setPaymentButtonFlag(false);
  };

  //function to handel user email field
  const handelEmailField = (e) => {
    let value = e.target.value;
    const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!emailRegEx.test(value) && value !== "") {
      setEmailError(`Please Enter Proper Email id`);
    } else {
      setEmailError("");
    }
    setUserEmailId(e.target.value);
    setIsUser(false);
    setButtonError(true);
    setUserData([]);
    setErrorMsg("");
    setPaymentButtonFlag(false);
  };

  // Function to handle verify user
  const handelVerifyButtonClick = () => {
    let reqObj = {
      phone: userPhone === "" ? undefined : userPhone,
      email: userEmailId === "" ? undefined : userEmailId,
    };
    dispatch(CheckUserAvailableDetails(reqObj)).then((data) => {
      if (!data?.error) {
        console.log(data?.isOurUser);
        setUserData(data?.data);
        setPaymentButtonFlag(true);
        setErrorMsg(data?.msg);
        setIsUser(data?.isOurUser);
        setReciverInfo((prev) => ({
          ...prev,
          phone: userPhone,
          email: userEmailId,
        }));
        if (userEmailId === "") {
          setEmailError("Please Enter Proper Email id");
        } else if (userPhone === "") {
          setPhoneError("Please Enter Proper Phone Number");
        }
      } else if (data?.error) {
        setUserData(data?.data);
        setErrorMsg(data?.msg);
        setIsUser(data?.isOurUser);
        setReciverInfo((prev) => ({
          ...prev,
          phone: userPhone,
          email: userEmailId,
        }));
      }
    });
  };

  //function to provide passwordrequired to user
  const handelPasswordRequired = (e) => {
    if (e.target.checked) {
      setPasswordRequired(true);
    } else {
      setPasswordRequired(false);
    }
  };
  //function to track load wallet change
  const handelLoadWalletChange = (event) => {
    setLoadWalletFlag(event.target.value === "yes");
    setReciverInfo((prev) => ({
      ...prev,
      isLoadGiftMoneyDirectly: event.target.value === "yes",
    }));
  };

  //function to set user details based on user selected option
  const handelSelectedUserClick = (data) => {
    setUserPhone(data?.phone);
    setUserEmailId(data?.email);
    setUserVerifyFlag(true);
    setEmailError("");
    setPaymentButtonFlag(true);
  };

  //useeffect to get userdetails
  useEffect(() => {
    if (userVerifyFlag) {
      handelVerifyButtonClick();
      setUserVerifyFlag(false);
    }
  }, [userVerifyFlag]);

  //function to change function status
  const handelInfoDialogClose = () => {
    setOpenInfoDialog(false);
  };

  //function to uncheck notification
  const handelDisableNotify = () => {
    setNotifyUserClick(false);
    setPhoneError("");
    setEmailError("");
    setUserPhone("");
    setUserEmailId("");
    setErrorMsg("");
    setOpenInfoDialog(false);
  };

  return (
    <>
      <Grid
        container
        gap={1}
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
      >
        <Grid item xs={12} md={12}>
          <Box sx={buyGiftCard.imageBox}>
            <Typography sx={buyGiftCard.valueTypo}>
              Gift Card Value ₹ {price}
            </Typography>
            <Box sx={buyGiftCard.validUntilBox}>
              <Typography sx={buyGiftCard.validUntilTypo}>
                Valid until {giftCardDetails?.expiry_day_count} days
              </Typography>
            </Box>
            {[
              {
                src: GiftBOX,
                top: 50,
                left: 34,
                width: "5rem",
                height: "5rem",
              },
              { src: halfStar, top: 90, right: -5 },
              { src: Star, top: 20, left: 20 },
              { src: Star, top: 10, left: 100, height: "2.3rem" },
              { src: Star, top: 25, left: 200, height: "1.2rem" },
              { src: Star, top: 80, left: 150, height: "2rem" },
              { src: Star, top: 120, left: 240, height: "3rem" },
              { src: Star, top: 50, left: 340, height: "1rem" },
              { src: Star, top: 10, left: 440, height: "2rem" },
              { src: Star, top: 120, left: 540, height: "3rem" },
            ].map((props, index) => (
              <Box
                key={index}
                sx={{
                  position: "absolute",
                  top: props.top,
                  left: props.left,
                  right: props.right,
                  zIndex: 10,
                }}
              >
                <img
                  src={props.src}
                  alt="Bookwater"
                  style={{
                    transform: "rotate(30deg)",
                    opacity: props.opacity ?? 1,
                    height: props.height ?? "auto",
                    width: props.width ?? "auto",
                  }}
                />
              </Box>
            ))}
          </Box>
          <Box sx={buyGiftCard.divider}></Box>
        </Grid>

        <Grid container gap={1} alignItems="center" sx={{ padding: "0 1rem" }}>
          {" "}
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: "bold",
                color: appColors.darkGray,
              }}
            >
              Enter amount (Minimum amount ₹ {giftCardDetails?.minimum_amount})
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <StyledInputField
              placeholder="amount"
              text="number"
              handelTextField={handelAmountField}
              error={priceError}
              value={price}
            />
          </Grid>
          <Grid item xs={7}>
            <GiftCardPayOptions
              minimumValue={giftCardDetails?.minimum_amount}
              maximumValue={giftCardDetails?.maximum_amount}
              selectedValue={price}
              handelClick={handelClick}
            />
          </Grid>
        </Grid>
        <CustomDivider dividerText="Notify Receiver (optional)" />

        <Grid item xs={12} md={12} sx={{ padding: "0 1rem" }}>
          <Box sx={buyGiftCard.notifuReciverBox}>
            {notifyUserClick && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {errorMsg && (
                  <Typography
                    sx={{
                      color: appColors.sucessColor,
                      fontSize: appTheme.fontSizeSmall,
                      fontWeight: "600",
                      textTransform: "uppercase",
                    }}
                  >
                    {errorMsg}
                  </Typography>
                )}
                {userData?.length > 0 && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      {!isUser && (
                        <>
                          {userData?.map((data) => (
                            <Box
                              sx={{
                                p: 1,
                                border: "1.5px dashed grey",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "center",
                                borderRadius: ".5rem",
                              }}
                              onClick={() => handelSelectedUserClick(data)}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    color: appColors.headerTextColor,
                                    fontSize: appTheme.fontSizeSmall,
                                    fontWeight: "500",
                                  }}
                                >
                                  Phone: {data?.phone}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: appColors.headerTextColor,
                                    fontSize: appTheme.fontSizeSmall,
                                    fontWeight: "500",
                                  }}
                                >
                                  Email: {data?.email}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    color: appColors.textColorNew,
                                    fontSize: appTheme.fontSizeMedium,
                                    fontWeight: "600",
                                    marginLeft: ".5rem",
                                  }}
                                >
                                  Select
                                </Typography>
                              </Box>
                            </Box>
                          ))}{" "}
                        </>
                      )}
                    </Box>

                    {isUser && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: appColors.darkGray,
                          }}
                        >
                          Load receiver wallet directly
                        </Typography>
                        <RadioGroup
                          value={loadWalletFlag ? "yes" : "no"}
                          onChange={handelLoadWalletChange}
                          row
                          sx={{
                            "& .MuiRadio-root": {
                              color: appColors.darkGray, // Default color for radio buttons
                              "&.Mui-checked": {
                                color: appColors.darkGray, // Color when checked
                              },
                            },
                            "& .MuiFormControlLabel-label": {
                              color: appColors.darkGray, // Color for labels
                            },
                          }}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </Box>
                    )}
                  </>
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  <Box sx={{ width: "14rem" }}>
                    <StyledInputField
                      placeholder="Receiver Phone Number"
                      text="phone"
                      handelTextField={handelPhoneField}
                      error={phoneError}
                      value={userPhone}
                    />
                  </Box>
                  <Box sx={{ width: "14rem" }}>
                    <StyledInputField
                      placeholder="Receiver email id"
                      text="email"
                      handelTextField={handelEmailField}
                      error={emailError}
                      value={userEmailId}
                    />
                  </Box>

                  <ButtonCustom
                    title="Verify"
                    handleButtonClick={handelVerifyButtonClick}
                    styles={{
                      width: "6rem",
                      background: appColors.giftCardColor,
                    }}
                  />
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={passwordRequired}
                    onChange={handelPasswordRequired}
                    sx={{
                      color: appColors.darkGray, // Default color
                      "&.Mui-checked": {
                        color: appColors.darkGray, // Color when checked
                      },
                    }}
                  />
                }
                label={
                  <span style={{ color: appColors.darkGray }}>
                    Password protect your gift?
                  </span>
                }
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <NotificationInfoDialog
        setOpenInfoDialog={setOpenInfoDialog}
        openInfoDialog={openInfoDialog}
        infoDialogTextHeading={appMessages.giftCardRevertHeader}
        infoDialogTextMain={appMessages.giftCardRevertNotify}
        handelProceedAction={handelDisableNotify}
        handelInfoDialogClose={handelInfoDialogClose}
        closeButtonText="No"
        yesButtonText="Remove"
      />
    </>
  );
}

const buyGiftCard = {
  imageBox: {
    height: "10rem",
    width: "100%",
    overflow: "hidden",
    borderRadius: "1rem 1rem 0 0",
    position: "relative",
    background:
      "linear-gradient(45deg, rgba(3,142,194,1) 35%, rgba(90,208,254,1) 100%)",
  },
  valueTypo: {
    position: "absolute",
    bottom: 0,
    left: 27,
    color: "#fff",
    fontWeight: "bold",
  },
  validUntilBox: {
    position: "absolute",
    top: 40,
    right: 0,
    background: "#fff",
    width: "6.2rem",
    borderTopLeftRadius: "1rem",
    borderBottomLeftRadius: "1rem",
    boxShadow: 2,
    paddingRight: ".6rem",
  },
  validUntilTypo: {
    fontSize: ".7rem",
    fontWeight: "bold",
    color: appColors.textColorNew,
    padding: ".2rem",
    textAlign: "center",
  },
  divider: {
    borderBottom: "2px dashed gray",
    width: "100%",
    margin: "0rem 0",
  },
  enterMinimumAmount: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  notifuReciverBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 .5rem",
  },
};
